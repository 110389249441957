<template>
  <div>
    <video ref="video" class="w-100" @canplay="initCanvas()">Stream Unviable</video>
    <div
      v-if="!isLoading"
      class="mx-auto my-4 cursor-pointer circle-outline bg-white border border-dark border-1 d-flex justify-content-center align-items-center"
      @click.once="takePicture()"
    >
      <i class="ri-custom-small ri-camera-line text-dark"></i>
    </div>
    <canvas ref="canvas" style="display: none"></canvas>
  </div>
</template>

<script>
export default {
  name: 'VueSelfie',
  data() {
    return {
      video: null,
      canvas: null,
      localStream: null,
      isLoading: false,
    }
  },
  mounted() {
    this.canvas = this.$refs.canvas
    this.video = this.$refs.video
    this.video.style.width = document.width + 'px'
    this.video.style.height = document.height + 'px'
    this.video.setAttribute('autoplay', '')
    this.video.setAttribute('muted', '')
    this.video.setAttribute('playsinline', '')
    this.startCapture()
  },
  created() {},
  methods: {
    startCapture() {
      navigator.mediaDevices
        .getUserMedia({
          video: {
            facingMode: 'user',
          },
          audio: false,
        })
        .then((stream) => {
          this.video.srcObject = stream
          this.video.play()
        })
        .catch((error) => {
          this.showAlert(error)
        })
    },
    takePicture() {
      this.isLoading = true
      let context = this.canvas.getContext('2d')
      context.drawImage(this.video, 0, 0, this.video.videoWidth, this.video.videoHeight)

      this.$emit('picture-taken', this.canvas.toDataURL('image/png'))
      this.isLoading = false
    },
    initCanvas() {
      this.canvas.setAttribute('width', this.video.videoWidth)
      this.canvas.setAttribute('height', this.video.videoHeight)
    },
  },
}
</script>

<style>
video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
