import client from '@/api/AxiosInstance.js'
export default {
  getFormData() {
    return client.get('form/type/default')
  },

  saveFormData(params) {
    return client.post('form/type/default/step/' + params.type, {
      version_id: params.version_id,
      input_data: params.input_data,
    })
  },

  getDocumentFormData(params) {
    return client.get('form/type/default/upload-file?version_id=' + params.version_id)
  },

  uploadDocument(params) {
    return client.post('form/type/default/upload-file', params)
  },

  updateDocument(params) {
    return client.post('form/type/default/update-file', params)
  },

  deleteDocument(params) {
    return client.post('form/type/default/delete', params)
  },

  submitUpdateDocuemnt(params) {
    return client.post('form/finish/documentupload', {
      loan_application_id: params.loan_application_id,
    })
  },

  async convertURLtoFile(url) {
    const response = await fetch(url)
    const data = await response.blob()
    const filename = url.split('/').pop() // url 구조에 맞게 수정할 것
    const metadata = { type: `image/jpeg` }
    return new File([data], filename, metadata)
  },
}
