<template>
  <div>
    <div class="img-upload d-flex align-items-center">
      <!-- preview pick image -->
      <b-img v-if="fileUrl && fileType != 'application/pdf'" :src="fileUrl" fluid alt></b-img>
      <b-img v-else-if="fileUrl && fileType == 'application/pdf'" :src="pdfImage" fluid alt></b-img>

      <!-- remove Icon -->
      <i
        v-if="fileUrl && $route.name != 'profile'"
        class="img-cancel ri-close-circle-fill ri-2x text-danger"
        @click="removeImage"
      ></i>

      <i v-if="!fileUrl" class="ri-add-line ri-2x text-light mx-auto" @click="pickUpImage()"></i>

      <input ref="fileInput" type="file" style="display: none" :accept="control.accept" @change="onFilePicked" />

      <div v-if="control.isSelfie">
        <b-modal id="modal-selfie" title="Selfie" centered hide-footer>
          <VueSelfie @picture-taken="getPicture"></VueSelfie>
        </b-modal>
      </div>
    </div>
    <loading :is-busy="isBusy"></loading>
  </div>
</template>

<script>
import { BaseControlSkeleton } from 'v-form-builder'
import FormService from '@/api/service/FormService'
import { bus } from '@/main.js'
// import {
//   Camera,
//   CameraDirection,
//   CameraResultType,
//   CameraSource
// } from "@capacitor/camera";
import VueSelfie from './VueSelfie.vue'
import Loading from '@/components/Loading.vue'
export default {
  name: 'ImageControl',
  components: { VueSelfie, Loading },
  extends: BaseControlSkeleton,
  data() {
    return {
      pdfImage: require('@/assets/images/pdf_logo.png'),
      fileUrl: null,
      fileType: null,
      filename: null,
      id: 0,
      isBusy: false,
    }
  },
  computed: {
    uploadedDocumentList() {
      return this.$store.getters['form/uploadedDocumentList']
    },
  },
  watch: {
    uploadedDocumentList() {
      this.checkDocument()
    },
  },
  methods: {
    checkDocument() {
      this.uploadedDocumentList.forEach((document) => {
        if (document.unique_id == this.control.uniqueId) {
          this.fileUrl = document.doc_url
          this.filename = document.doc_name
          this.fileType = document.doc_type
          this.id = document.id
        }
      })
    },
    pickUpImage() {
      if (this.control.isSelfie) {
        this.takePicture()
      } else {
        this.onPickFile()
      }
    },
    takePicture() {
      navigator.mediaDevices
        .getUserMedia({ video: true, audio: false })
        .then((stream) => {
          console.log(stream)
          this.$bvModal.show('modal-selfie')
        })
        .catch((error) => {
          this.showAlert(error.message)
        })
    },
    async getPicture(url) {
      this.$bvModal.hide('modal-selfie')
      const fileUrl = await FormService.convertURLtoFile(url)
      /**
       *
       */
      this.updateValue(fileUrl.name)
      /**
       *
       */
      this.uploadDocument(fileUrl)
    },
    // async takePicture() {
    //   try {
    //     const image = await Camera.getPhoto({
    //       quality: 80,
    //       allowEditing: false,
    //       resultType: CameraResultType.Uri,
    //       source: CameraSource.Camera,
    //       direction: CameraDirection.Front,
    //       saveToGallery: false
    //     });
    //     this.fileUrl = image.webPath;

    //     const fileUrl = await FormService.convertURLtoFile(this.fileUrl);
    //     /**
    //      *
    //      */
    //     this.updateValue(fileUrl.name);
    //     /**
    //      *
    //      */
    //     this.uploadDocument(fileUrl);
    //   } catch (err) {
    //     this.showAlert(err.message);
    //   }
    // },

    onPickFile() {
      this.$refs.fileInput.click()
    },
    onFilePicked(event) {
      const files = event.target.files
      let fileUrl = null
      this.fileType = files[0].type
      if (this.fileType.includes('pdf') || this.fileType.includes('image')) {
        this.filename = files[0].name
        const fileReader = new FileReader()
        fileReader.addEventListener('load', () => {
          fileUrl = fileReader.result
        })
        fileReader.readAsDataURL(files[0])
        fileUrl = files[0]
        /**
         *
         */
        this.updateValue(this.filename)
        /**
         *
         */

        this.uploadDocument(fileUrl)
      } else {
        this.showAlert(this.$t('image_pdf_allow'))
      }
    },
    removeImage() {
      this.$bvModal
        .msgBoxConfirm(this.$t('confirm_delete_image'), {
          hideTitle: true,
          size: 'sm',
          buttonSize: 'sm',
          bodyClass: 'p-4',
          okVariant: 'primary',
          cancelVariant: 'primary-outline',
          okTitle: this.$t('delete'),
          cancelTitle: this.$t('not_delete'),
          footerClass: 'p-2',
          scrollable: true,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.fileUrl = null
            this.filename = null
            /**
             *
             */
            this.updateValue('')
            this.deleteDocument()
          }
        })
        .catch((err) => {
          console.log(err)
          // An error occurred
        })
    },
    async uploadDocument(fileUrl) {
      this.isBusy = true
      try {
        var formData = new FormData()
        formData.append('document', fileUrl)
        formData.append('version_id', this.control.versionId)
        formData.append('unique_id', this.control.uniqueId)

        await FormService.uploadDocument(formData)
        this.isBusy = false
        bus.$emit('uploadDocument', 'uploaded')
      } catch (err) {
        this.isBusy = false
        bus.$emit('uploadDocument', 'uploaded')
        this.showAlert(err)
      }
    },

    async deleteDocument() {
      this.isBusy = true
      try {
        await FormService.deleteDocument({
          version_id: this.control.versionId,
          unique_id: this.control.uniqueId,
          id: this.id,
        })
        this.isBusy = false
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
  },
}
</script>

<style></style>
